import rawData from './data.json';

const sparklineData = [
  54, 53, 63, 80, 94, 52, 62, 85, 90, 98, 57, 70, 87, 78, 89, 96, 92, 79, 65, 64,
];

const properties = [
  {
    id: '1',
    name: 'Mariehem',
    address: 'Mariehemsvägen 37',
    location: 'Umeå',
    selectables: [
      {
        identifier: '',
        name: '',
        displayLink: false,
        info: [
          { key: '', value: '' },
        ],
        displayData: true,
        data: sparklineData,
      },
    ],
    markers: [
      {
        identifier: '',
        variable: '',
      },
    ],
    buildings: {
      'Mariehemsvägen 37': {
        floors: [
          'Våning 1',
          'Våning 2',
        ],
      },
      'Mariehemsvägen 41': {
        floors: [
          'Våning 1',
          'Våning 2',
        ],
      },
    },
    coordinates: {
      lat: 63.843035,
      lng: 20.329888,
    },
    recommendedActions: {
      heating: 'Din returtemperatur på Mariehemsvägen 37 och Mariehemsvägen 41 är inte optimal. Vill du ha hjälp att optimera din undercentral kontakta Umeå Energis fastighetsservice på 070-777 777 eller service@placeholder.se.',
      power: 'Du har i April haft en estimerad potiential i solceller om 81kwh baserat på en ledig takyta om 45kvm. För att komma igång med solceller kontakta Umeå energi på 070-777 777 eller service@placeholder.se.',
    },
  },
  {
    id: '2',
    name: 'Mariehöjd',
    address: 'Skärvstensgatan 24-26',
    location: 'Umeå',
    selectables: [
      {
        identifier: '0-0-45',
        name: 'Taket',
        displayLink: false,
        info: [
          { key: 'Storlek', value: '33 m2' },
          { key: 'Lutning', value: '32 deg' },
          { key: 'Väderstreck', value: 'Söder' },
        ],
        displayData: true,
        data: sparklineData,
      },
      {
        identifier: '0-0-66',
        name: 'Lägenhet 1b',
        displayLink: true,
        info: [
          { key: 'Storlek', value: '43 m2' },
          { key: 'Ägare', value: 'Mazzy Star' },
        ],
        displayData: false,
      },
    ],
    markers: [
      {
        identifier: '0-0-45',
        variable: 'returntempscore',
      },
    ],
    buildings: {
      'Skärvstensgatan 24-26': {
        floors: [
          'Våning 1',
          'Våning 2',
          'Våning 3',
          'Våning 4',
          'Våning 5',
          'Våning 6',
        ],
      },
      'Skärvstensgatan 28-30': {
        floors: [
          'Våning 1',
          'Våning 2',
          'Våning 3',
          'Våning 4',
          'Våning 5',
          'Våning 6',
        ],
      },
    },
    coordinates: {
      lat: 63.829732,
      lng: 20.326552,
    },
    recommendedActions: {
      heating: 'Din returtemperatur i Skärvstensgatan 28-30 är hög vilket resulterar i ett sänkt energibetyg. Orsaker till den förhöjda temperaturen kan vara felaktigt inställd värmekurva eller pumphastighet. Vill du ha hjälp att optimera din undercentral kontakta Umeå Energis fastighetsservice på 070-777 777 eller service@placeholder.se.',
      power: 'Skärvstensgatan 28-30 visar på en högre baslast på el. Det finns många sätt att sänka sin baslast, belysning schemaläggning av strömsparläge eller optimering av ventilation för att nämna några. Kontakta gärna Umeå Energis experter på 070-777 777 eller service@placeholder.se så kan de hjälpa dig.',
    },
  },
  {
    id: '3',
    name: 'Utopia',
    address: 'Kungsgatan 65',
    location: 'Umeå',
    selectables: [
      {
        identifier: '',
        name: '',
        displayLink: false,
        info: [
          { key: '', value: '' },
        ],
        displayData: true,
        data: sparklineData,
      },
    ],
    markers: [
      {
        identifier: '',
        variable: '',
      },
    ],
    buildings: {
      Utopia: {
        floors: [
          'Våning 1',
          'Våning 2',
          'Våning 3',
        ],
      },
    },
    coordinates: {
      lat: 63.825950,
      lng: 20.265046,
    },
    recommendedActions: {
      heating: 'Din returtemperatur i Skärvstensgatan 28-30 är hög vilket resulterar i ett sänkt energibetyg. Orsaker till den förhöjda temperaturen kan vara felaktigt inställd värmekurva eller pumphastighet. Vill du ha hjälp att optimera din undercentral kontakta Umeå Energis fastighetsservice på 070-777 777 eller service@placeholder.se.',
      power: 'Du har i April haft en estimerad potiential i solceller om 81kwh baserat på en ledig takyta om 45kvm. För att komma igång med solceller kontakta Umeå energi på 070-777 777 eller service@placeholder.se.',
    },
  },
];

const sensors = {
  returntempscore: {
    name: 'Energibetyg: returtemperatur',
    label: 'Senaste poäng',
    variable: 'returntempscore',
    labelUnit: '/ 10',
    unit: 'kWh',
    max: 10,
  },
  consumption: {
    name: 'Energianvändning',
    label: 'Senaste genomsnitt',
    variable: 'consumption',
    labelUnit: 'kWh',
    unit: 'kWh',
    max: 40,
  },
  flow: {
    name: 'Flöde',
    label: 'Senaste genomsnitt',
    variable: 'flow',
    labelUnit: 'm3/h',
    unit: 'm3/h',
    max: 2,
  },
  intemp: {
    name: 'Ingående temperatur',
    label: 'Senaste genomsnitt',
    variable: 'intemp',
    labelUnit: '°C',
    unit: '°C',
    max: 120,
  },
  outtemp: {
    name: 'Returtemperatur',
    label: 'Senaste genomsnitt',
    variable: 'outtemp',
    labelUnit: '°C',
    unit: '°C',
    max: 50,
  },
  power: {
    name: 'Elanvändning',
    label: 'Genomsnitt',
    variable: 'kWh',
    labelUnit: 'kWh',
    unit: 'kwp',
    max: 40,
  },
  kwp: {
    name: 'Potential solceller',
    label: 'Senaste genomsnitt',
    variable: 'kwp',
    labelUnit: 'kwp',
    unit: 'kwp',
    max: 2,
  },
  basepower: {
    name: 'Baseline power',
    label: 'Senaste genomsnitt',
    variable: 'basepower',
    labelUnit: 'kWh',
    unit: 'kWh',
    max: 20,
  },
};

properties.forEach((property) => {
  const prop = property;
  Object.keys(property.buildings).forEach((building) => {
    if (rawData[building]) {
      prop.buildings[building].values = rawData[building];
    }
  });
});

export {
  properties,
  sensors,
};
