import styled from '@emotion/styled';
import {
  Panel,
  Title,
  useTheme,
} from '@teron/fabric';
import React from 'react';
import Select from 'react-select';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { properties } from '../data';
import { usePropertiesContext } from '../providers/PropertiesProvider';

const StyledSliderContainer = styled.div`
  padding: 18px 12px 20px 12px;
`;

const marksBuildings = {
  1: 1, 2: 2, 3: 3, 4: 4, 5: 5,
};
const marksFloors = {
  1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8,
};

const Filter = () => {
  const { theme } = useTheme();
  const {
    areaShown, buildingsRange, floorsRange, disableRange,
  } = usePropertiesContext();
  const context = usePropertiesContext();

  const optionsList = [{ value: 'Samtliga fastigheter', label: 'Samtliga fastigheter' }];
  const optionsMapped = properties.map(({ name }) => [{ value: name, label: name }]);
  optionsList.push(optionsMapped);
  const options = optionsList.flat(2);

  return (
    <Panel padding='reduced' style={{ height: 'max-content' }}>
      <div style={{ width: '100%' }}>
        <Select
          defaultValue={options[0]}
          placeholder={areaShown}
          value={options.find((option) => option.value === areaShown)}
          isSearchable
          options={options}
          onChange={(option) => context.setAreaShown(option.value)}
          theme={(selectTheme) => ({
            ...selectTheme,
            colors: {
              ...selectTheme.colors,
              neutral0: `${theme.contexts.light.background.base}`,
              neutral80: `${theme.contexts.light.text.base}`,
              primary: '#007E31',
              primary25: 'rgba(0, 126, 49, 0.3)',
              primary50: 'rgba(0, 126, 49, 0.3)',
            },
          })}
        />
      </div>
        <StyledSliderContainer>
          <Title level='h5' margin='compact'>Antal byggnader / fastighet:</Title>
          <Range
            min={1}
            max={5}
            defaultValue={[1, 5]}
            value={buildingsRange}
            allowCross={false}
            marks={marksBuildings}
            onChange={(range) => context.setBuildingsRange(range)}
            disabled={disableRange}
            trackStyle={[{ background: '#007E31' }]}
            railStyle={{ background: 'rgba(0, 126, 49, 0.3)' }}
            dotStyle={{ borderColor: 'rgba(0, 126, 49, 0.3)' }}
            activeDotStyle={{ borderColor: '#007E31', background: '#007E31' }}
            handleStyle={[{ borderColor: '#007E31', background: '#007E31' },
              { borderColor: '#007E31', background: '#007E31' },
            ]}
          />
        </StyledSliderContainer>
        <StyledSliderContainer>
          <Title level='h5' margin='compact'>Antal våningar / byggnad:</Title>
          <Range
            min={1}
            max={8}
            defaultValue={[1, 8]}
            value={floorsRange}
            allowCross={false}
            marks={marksFloors}
            onChange={(range) => context.setFloorsRange(range)}
            disabled={disableRange}
            trackStyle={[{ background: '#007E31' }]}
            railStyle={{ background: 'rgba(0, 126, 49, 0.3)' }}
            dotStyle={{ borderColor: 'rgba(0, 126, 49, 0.3)' }}
            activeDotStyle={{ borderColor: '#007E31', background: '#007E31' }}
            handleStyle={[{ borderColor: '#007E31', background: '#007E31' },
              { borderColor: '#007E31', background: '#007E31' },
            ]}
          />
        </StyledSliderContainer>
    </Panel>
  );
};

export default Filter;
