import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useTheme } from '@teron/fabric';
import { properties } from '../data';

const NavigationDropdown = () => {
  const history = useHistory();
  const { id: currentId } = useParams();
  const { theme } = useTheme();

  const optionsList = properties.map(({ id, name }) => [{ value: id, label: name }]);
  const options = optionsList.flat();

  const handleInputChange = (input) => {
    history.push(`/properties/${input}/`);
  };

  return (
    <div style={{ margin: 'auto 0 auto 24px', width: '150px' }}>
      <Select
        defaultValue={options[currentId - 1]}
        isSearchable
        options={options}
        onChange={(event) => handleInputChange(event.value)}
        theme={(selectTheme) => ({
          ...selectTheme,
          colors: {
            ...selectTheme.colors,
            neutral0: `${theme.contexts.light.background.base}`,
            neutral80: `${theme.contexts.light.text.base}`,
            primary: '#007E31',
            primary25: 'rgba(0, 126, 49, 0.3)',
            primary50: 'rgba(0, 126, 49, 0.3)',
          },
        })}
      />
    </div>
  );
};

export default NavigationDropdown;
