import React, { useCallback, useEffect, useState } from 'react';
import {
  useTheme,
} from '@teron/fabric';

import { AxisBottom, AxisLeft } from '@vx/axis';
import { Group } from '@vx/group';
import { scaleLinear, scaleTime } from '@vx/scale';
import { LinePath, Line, Bar } from '@vx/shape';
import { localPoint } from '@vx/event';
import {
  extent,
  max,
  min,
  bisector,
} from 'd3-array';
import { GridRows, GridColumns } from '@vx/grid';
import { useTooltip, Tooltip, defaultStyles } from '@vx/tooltip';

import { sensors as sensorsMeta } from '../data';

const LineChart = ({
  series,
  activeSeries,
  column,
  width,
  height,
}) => {
  const { theme: { context } } = useTheme();
  const [{ xMax, yMax }, setMax] = useState({ xMax: 0, yMax: 0 });

  const getX = (d) => new Date(d.date).valueOf();
  const getY = (d, index) => d[column[index]];

  const bisectDate = bisector((d) => new Date(d.date)).left;
  // const getDate = (d) => new Date(d.date);

  const marginLeft = 60;
  const marginBottom = 80;

  useEffect(() => {
    if (width !== 0 && height !== 0) {
      setMax({ xMax: width - marginLeft, yMax: height - marginBottom });
    }
  }, [width, height]);

  const valueSpace = activeSeries.reduce((acc, id) => [
    ...acc,
    ...series[id].values,
  ], []).sort((a, b) => a.date - b.date);

  const xScale = scaleTime({
    range: [0, xMax],
    domain: extent(valueSpace, getX),
  });

  const getYDomain = (operator) => (d) => operator(column.map((col) => d[col]));
  const domain = [min(valueSpace, getYDomain(min)), max(valueSpace, getYDomain(max))];
  const diff = domain[1] - domain[0];
  const padding = 0.25;

  const yScale = scaleLinear({
    range: [yMax, 0],
    domain: [domain[0] - padding * diff, domain[1] + padding * diff],
  });

  const {
    showTooltip,
    tooltipData,
    tooltipLeft,
    tooltipTop,
    hideTooltip,
  } = useTooltip();

  const handleTooltip = useCallback((event) => {
    let { x } = localPoint(event) || { x: 0 };
    x -= marginLeft;

    const x0 = xScale.invert(x);
    const index = bisectDate(valueSpace, x0, 1);
    const d = valueSpace[index - 1];

    showTooltip({
      tooltipData: d,
      tooltipLeft: x,
      tooltipTop: yScale(getY(d, 0)),
    });
  }, [showTooltip, yScale, xScale]);

  return (
      <>
        <svg width={width} height={height}>
          <Group top={0} left={marginLeft} fill="green">
            <AxisLeft
              scale={yScale}
              numTicks={4}
              stroke={context.dividerFaint.base}
              tickStroke={context.dividerFaint.base}
              tickLabelProps={() => ({
                fill: context.textMuted.base,
                fontSize: 10,
                fontFamily: 'sans-serif',
                textAnchor: 'end',
                dx: '-0.25em',
                dy: '0.25em',
              })}
            />
            <AxisBottom
              scale={xScale}
              numTicks={5}
              top={yMax}
              stroke={context.dividerFaint.base}
              tickStroke={context.dividerFaint.base}
              tickLabelProps={() => ({
                fill: context.textMuted.base,
                fontSize: 10,
                fontFamily: 'sans-serif',
                textAnchor: 'middle',
              })}
            />
            <GridRows
              scale={yScale}
              width={xMax}
              strokeDasharray="3,3"
              stroke={context.dividerFaint.base}
            />
            <GridColumns
              scale={xScale}
              height={yMax}
              strokeDasharray="3,3"
              stroke={context.dividerFaint.base}
            />
            {column.map((item, index) => activeSeries.map((id, indx) => <LinePath
              key={indx}
              data={series[id].values}
              x={(d) => xScale(getX(d))}
              y={(d) => yScale(getY(d, index))}
              stroke={series[id].color}
              strokeWidth={1.5}
            />))}
            {tooltipData && <Line
              from={{ x: tooltipLeft, y: 0 }}
              to={{ x: tooltipLeft, y: yMax }}
              strokeDasharray="5,2"
              stroke={context.divider.base}
            />}
          </Group>
          <Bar
            x={marginLeft}
            y={0}
            width={xMax}
            height={yMax}
            fill="transparent"
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
        </svg>
        {tooltipData && <div><Tooltip
          top={tooltipTop + marginBottom}
          left={tooltipLeft}
          styles={{
            ...defaultStyles,
            background: 'red',
            color: 'white',
          }}
        >
          {activeSeries.map((id) => {
            const { color } = series[id];
            return <div key={id} style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  height: '0.3rem',
                  width: '0.6rem',
                  background: color,
                  marginRight: '0.5rem',
                }}
              />
              {column.map((item, index) => <div key={index} style={{ display: 'flex' }}>
                <div>{series[id].values.find(({ date: _date }) => (
                  _date === tooltipData.date
                ))[item].toFixed(2)}</div>
                <div style={{ marginLeft: '0.2rem', marginRight: '0.5rem' }}>{sensorsMeta[item].unit}</div>
              </div>)}
              </div>;
          })}
          <div style={{ marginTop: '0.1rem' }}>{tooltipData.date}</div>
        </Tooltip></div>}
      </>
  );
};

export default LineChart;
