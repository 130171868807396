import React from 'react';
import { render } from 'react-dom';
import {
  App,
  defaultDarkTheme,
  defaultLightTheme,
  extendTheme,
  mergeThemes,
} from '@teron/fabric';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Navbar from './components/Navbar';
import PropertiesView from './views/PropertiesView';
import PropertyView from './views/PropertyView';
import { PropertiesProvider } from './providers/PropertiesProvider';

const authConfig = {
  realm: 'ue',
  url: 'https://keycloak.trell.tech/auth/',
  clientId: 'dev',
  scope: 'dev',
  enableLogging: true,
};

const Wrapper = () => {
  const themes = [
    extendTheme(
      mergeThemes(defaultLightTheme, {
        name: 'custom-light-theme',
        ui: {
          root: {
            base: '#F8F8F8',
          },
          primary: {
            base: '#007E31',
          },
          warning: {
            base: '#F7D157',
          },
        },
      }),
    ),
    extendTheme(
      mergeThemes(defaultDarkTheme, {
        name: 'custom-dark-theme',
        ui: {
          primary: {
            base: '#1e984d',
          },
          root: {
            base: '#242424',
          },
          light: {
            base: '#282828',
          },
          dark: {
            base: '#202020',
          },
          warning: {
            base: '#c2a54a',
            contrast: '#000',
          },
        },
        external: {
          mapbox: 'mapbox://styles/gurraman/ckg6z02jt3rpi19obsahwg0y0',
        },
      }),
    ),
  ];

  return (
    <Router>
      <App
        themes={themes}
        themeName={'custom-light-theme'}
        authConfig={authConfig}
      >
        <Navbar />
        <Switch>
          <Route path="/" exact>
            <PropertiesProvider>
              <PropertiesView />
            </PropertiesProvider>
          </Route>
          <Route path="/properties/:id/">
            <PropertyView />
          </Route>
        </Switch>
      </App>
    </Router>
  );
};

render(<Wrapper />, document.getElementById('app'));
