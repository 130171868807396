import React, { useState } from 'react';
import {
  Cell,
  Grid,
  Icon,
  VerticalNavbar,
  Navlink,
  useTheme,
} from '@teron/fabric';
import { NavLink as Link, useParams, Route } from 'react-router-dom';
import styled from '@emotion/styled';

import Logo from './Logo';

const StyledToggler = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  align-items: center;
  width: 100%;
`;

const StyledSeparator = styled.div`
  height: 1px;
  margin: 0.5rem;
  background: #FFF2;
`;

const ExtraLinks = () => {
  const { id } = useParams();

  return <>
    <StyledSeparator />
    <Navlink
      component={Link}
      to={`/properties/${id}/heating/`}
      icon="brightness-7"
      title="Värme"
    />
    <Navlink
      component={Link}
      to={`/properties/${id}/power/`}
      icon="power"
      title="El"
    />
  </>;
};

const Navbar = () => {
  const { toggleTheme, theme } = useTheme();
  const [contract, setContract] = useState(true);

  const toggleCompact = () => setContract(!contract);

  const toggler = (isActive) => ({ children, ...props }) => (
    <StyledToggler {...props}>
      {children} <Icon icon={isActive ? 'toggle-on' : 'toggle-off'} />
    </StyledToggler>
  );

  return (
    <VerticalNavbar contract={contract}>
      <Grid columns="1fr auto" align="top" vertical style={{ height: '100%' }}>
        <Cell>
          <Navlink
            to="/"
            icon={<Logo />}
            title="Umeå Energi"
            component={Link}
            exact
          />
          <Route path="/properties/:id/">
            <ExtraLinks />
          </Route>
        </Cell>
        <Cell>
          <Navlink
            to="/user/"
            icon="face"
            title="Användare"
            component={Link}
          />
          <Navlink
            titleComponent={toggler(!contract)}
            onClick={toggleCompact}
            icon="burst-mode"
            title="Statisk sidomeny"
          />
          <Navlink
            titleComponent={toggler(theme.name === 'custom-dark-theme')}
            onClick={toggleTheme}
            icon="timelapse"
            title="Mörkerläge"
          />
        </Cell>
      </Grid>
    </VerticalNavbar>
  );
};

export default Navbar;
