import React from 'react';
import {
  useParams,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import {
  Grid,
  Cell,
  Page,
  PageContent,
  PageHeader,
  Title,
} from '@teron/fabric';

import houseModelMariehojd from '../assets/dubbelhus.glb';
import floorModelMariehojd from '../assets/vaningsplan.glb';
import placeholderModelUtopia from '../assets/scene2.glb';

import ChartsComponent from '../components/ChartsComponent';
import ModelView from '../components/ModelView';
import Weather from '../components/Weather';
import RecommendedActions from '../components/RecommendedActions';
import NavigationDropdown from '../components/NavigationDropdown';
import { properties } from '../data';

const Property = () => {
  const { id } = useParams();
  const property = properties.find(({ id: _id }) => _id === id);

  return <>
    <Route path="/properties/:id/" exact>
      <Redirect to={`/properties/${id}/heating/`} />
    </Route>
    <Page>
      <PageHeader pretitle={property.name} title={
        <Switch>
          <Route path="/properties/:id/heating/">
            Värme
          </Route>
          <Route path="/properties/:id/power/">
            El
          </Route>
        </Switch>
        }
      >
        <Weather temperature={22.2} ppm={220} humidity={30} />
        <NavigationDropdown />
      </PageHeader>
      <PageContent>
        <Grid vertical align="top">
          <Cell>
            <Route path={'/properties/1/'}>
              <ModelView
              property={property}
              houseModel={houseModelMariehojd}
              floorModel={floorModelMariehojd}
            />
            </Route>
            <Route path={'/properties/2/'}>
              <ModelView
              property={property}
              houseModel={houseModelMariehojd}
              floorModel={floorModelMariehojd}
            />
            </Route>
            <Route path={'/properties/3/'}>
              <ModelView
              property={property}
              houseModel={placeholderModelUtopia}
              floorModel={floorModelMariehojd}
            />
            </Route>
          </Cell>
          <Cell>
            <Title level="h2">Rekommenderade åtgärder</Title>
            <RecommendedActions property={property} />
          </Cell>
          <Cell>
            <Title level="h2">Användning</Title>
            <Route path="/properties/:id/heating/">
              <ChartsComponent
                sensors={['consumption', 'flow', 'intemp', 'outtemp']}
                barSensors={['returntempscore']}
                buildings={property.buildings}
              />
            </Route>
            <Route path="/properties/:id/power/">
              <ChartsComponent
                sensors={['power', 'kwp']}
                barSensors={['basepower']}
                buildings={property.buildings}
                yScaleHisto={true}
              />
            </Route>
          </Cell>
        </Grid>
      </PageContent>
    </Page>
  </>;
};

export default Property;
