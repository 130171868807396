import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
  Grid,
  Cell,
  Panel,
  Metric,
  Text,
  Title,
} from '@teron/fabric';
import { ParentSize } from '@vx/responsive';

import { sensors as sensorsMeta } from '../data';
import BarLine from './BarLine';

const StyledPanel = styled(Panel)`
 // TODO: Why does this not work?
  cursor: pointer;
`;

const SensorBars = ({
  sensors,
  buildings,
  activeSensor,
  setActiveSensor,
  activeBuilding,
  setActiveBuilding,
}) => (
  <Grid align="top">
    {Object.entries(buildings).map(([buildingId, { values }]) => (
      sensors.map((id) => {
        const {
          name,
          label,
          labelUnit,
          max,
        } = sensorsMeta[id];

        return <Cell width={1} key={id}>
          <StyledPanel
            style={{ cursor: 'pointer', width: `${Object.keys(buildings).length === 1 ? '49%' : '100%'}` }}
            active={activeSensor.includes(id) && activeBuilding === buildingId}
            onClick={() => {
              setActiveSensor([id]);
              setActiveBuilding(buildingId);
            }}
          >
            <Title margin="compact" actions={
              <Text appearance="textMuted">{buildingId}</Text>
            }>{name}</Title>
            <Metric
              css={css`
                div > div {
                  white-space: nowrap;
                }
              `}
              label={label}
              value={values[values.length - 1][id].toFixed(2)}
              max={max}
              unit={labelUnit}
              oversized
            >
              <div style={{ height: 50, width: '100%', marginLeft: '1rem' }}>
                <ParentSize>
                  {(parent) => (
                    <BarLine
                      data={values.filter(({ date }) => new Date(date).getHours() === 0)}
                      column={id}
                      width={parent.width}
                      height={parent.height}
                    />
                  )}
                </ParentSize>
              </div>
            </Metric>
          </StyledPanel>
        </Cell>;
      })
    ))}
  </Grid>
);

export default SensorBars;
