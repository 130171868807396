import React, { useState } from 'react';
import {
  Panel,
  Grid,
  Cell,
  Title,
  useColors,
} from '@teron/fabric';

import { ParentSize } from '@vx/responsive';

import { mergeSeries } from '../utils';
import { sensors as sensorsMeta } from '../data';

import SensorTabs from './SensorTabs';
import SensorBars from './SensorBars';
import BarLine from './BarLine';
import LineChart from './LineChart';

const Charts = ({
  series,
  activeSeries,
  column,
  width,
  height,
}) => (
  <LineChart
    series={series}
    activeSeries={activeSeries}
    column={column}
    width={width}
    height={height}
  />
);

const Histogram = ({
  data,
  column,
  width,
  height,
  color,
  yScaleHeight,
}) => (
  <BarLine
    data={data.filter(({ date }) => new Date(date).getHours() === 0)}
    column={column}
    width={width}
    height={height}
    color={color}
    showAxes
    yScaleHeight={yScaleHeight}
  />
);

const Legend = ({ series, activeSeries, toggleSeries }) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    {Object.entries(series).map(([id, { color }]) => (
      <div
        key={id}
        onClick={() => toggleSeries(id)}
        style={{
          marginRight: '1rem',
          opacity: activeSeries.includes(id) ? 1 : '0.5',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            height: '0.3rem',
            width: '0.6rem',
            background: color,
            marginRight: '0.5rem',
          }}
        />
        {id}
      </div>
    ))}
  </div>
);

const ChartsComponent = ({
  sensors = [],
  barSensors = [],
  buildings,
  yScaleHisto,
}) => {
  const [activeSensor, setActiveSensor] = useState([sensors[0]]);
  const [activeBuilding, setActiveBuilding] = useState(null);
  const [activeSeries, setActiveSeries] = useState(Object.keys(buildings));
  const { qualitative: { default: colors } } = useColors();

  const values = activeSeries.length
    ? mergeSeries(...activeSeries.map((id) => buildings[id].values))
    : [];

  const toggleSeries = (id) => setActiveSeries(activeSeries.includes(id)
    ? activeSeries.filter((_id) => _id !== id)
    : [...activeSeries, id]);

  const series = Object.keys(buildings).reduce((acc, key, i) => ({
    ...acc,
    [key]: {
      ...buildings[key],
      color: colors[i % colors.length],
    },
  }), {});

  const addTitle = () => {
    if (activeSensor.length === 1) {
      return sensorsMeta[activeSensor].name;
    }
    const formatingTitle = activeSensor.map((sensor) => {
      const formatingMiddle = activeSensor.length > 2 && activeSensor[activeSensor.length - 2] !== sensor ? `${sensorsMeta[sensor].name}, ` : `${sensorsMeta[sensor].name} `;
      return activeSensor[activeSensor.length - 1] === sensor ? `och ${sensorsMeta[sensor].name}` : formatingMiddle;
    });
    return formatingTitle;
  };

  return (
    <Grid vertical align="top">
      {barSensors.length > 0 && (
        <Cell>
          <SensorBars
            sensors={barSensors}
            buildings={buildings}
            activeSensor={activeSensor}
            setActiveSensor={setActiveSensor}
            activeBuilding={activeBuilding}
            setActiveBuilding={setActiveBuilding}
          />
        </Cell>
      )}
      <Cell>
        <SensorTabs
          sensors={sensors}
          activeSensor={activeSensor}
          setActiveSensor={setActiveSensor}
          setActiveBuilding={setActiveBuilding}
          values={values}
        />
      </Cell>
      <Cell>
        <Panel>
          <Title>{addTitle()}</Title>
          <div style={{ height: 400 }}>
            <ParentSize>
              {(parent) => (
                activeBuilding !== null
                  ? <Histogram
                    data={series[activeBuilding].values}
                    color={series[activeBuilding].color}
                    column={activeSensor}
                    width={parent.width}
                    height={parent.height}
                    yScaleHeight={yScaleHisto}
                  />
                  : <Charts
                    series={series}
                    activeSeries={activeSeries}
                    column={activeSensor}
                    width={parent.width}
                    height={parent.height}
                  />
              )}
            </ParentSize>
          </div>
          {activeBuilding === null && <Legend
            series={series}
            activeSeries={activeSeries}
            toggleSeries={toggleSeries}
          />}
        </Panel>
      </Cell>
    </Grid>
  );
};

export default ChartsComponent;
