import React, { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import {
  Container,
  Button,
  Icon,
  Panel,
  Prop,
  Metric,
  Title,
  BinaryDonut,
  PropTable,
  useTheme,
  Sparkline,
} from '@teron/fabric';
import ModelViewer, { Marker } from 'trell-model-viewer';
import { sensors as sensorsMeta } from '../data';
import { mergeSeries } from '../utils';

const StyledComponentOverlay = styled.div`
  background: ${({ theme }) => theme.contexts.overlay.background.base};
  color: ${({ theme }) => theme.contexts.overlay.text.base};
  position: absolute;
  right: 1rem;
  top: 1rem;
  padding: 1rem;
  border-radius: 0.2rem;
  min-width: 15rem;
  overflow: auto;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const viewerConfig = {
  width: '100%',
  height: 400,
  flyTo: false,
  autoCameraReset: false,
};

const ComponentProps = ({ component }) => (
  <PropTable>
    {component.info.map(({ key, value }) => (
      <Prop key={key} name={key}>
        {value}
      </Prop>
    ))}
  </PropTable>
);

const ComponentInfo = ({
  theme,
  componentId,
  component,
  onClose,
  toggleModel,
}) => (
  <StyledComponentOverlay theme={theme}>
    <StyledHeader>
      {component ? component.name : componentId}
      <Icon icon="highlight-off" onClick={onClose} />
    </StyledHeader>
      {component ? <ComponentProps component={component} /> : 'No info'}

    {component && component.displayLink
      ? <div><Button onClick={() => {
        onClose();
        toggleModel();
      }}>Visa våning</Button></div>
      : null
    }
    {component && component.displayData
      ? <div style={{
        height: '4rem',
        width: '13rem',
      }}><Sparkline values={component.data} /></div>
      : null
    }
  </StyledComponentOverlay>
);

const ModelComponent = ({
  houseModel,
  floorModel,
  property,
}) => {
  const selectables = [...new Set([
    ...property.selectables.map(({ identifier }) => ({ identifier })),
    ...property.markers.map(({ identifier }) => ({ identifier })),
  ])];

  const values = useMemo(() => (
    Object.keys(property.buildings).length
      ? mergeSeries(...Object.values(property.buildings).map(({ values: _values }) => _values))
      : []
  ), [property]);

  const { theme } = useTheme();
  const { context } = theme;
  const [showFloorModel, setShowFloorModel] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [viewerState, setViewerState] = useState({
    selectedComponentIdentifier: null,
    selectables,
    highlightedComponents: [],
    markers: [],
    markersVisible: true,
    pointerDown: false,
  });
  const floorViewerState = ({
    pointerDown: false,
  });

  const onViewerStateUpdate = (update) => {
    setViewerState({ ...viewerState, ...update });
  };

  const selectedComponent = viewerState.selectedComponentIdentifier !== null
    ? property.selectables.find(({ identifier }) => (
      identifier === viewerState.selectedComponentIdentifier
    ))
    : null;

  const deselect = () => {
    setViewerState({ ...viewerState, selectedComponentIdentifier: null });
  };

  const toggleModel = () => setShowFloorModel(!showFloorModel);

  const generateMarkers = () => (
    viewerState.markers
      .filter(({ identifier }) => property.markers
        .map(({ identifier: _identifier }) => _identifier)
        .includes(identifier))
      .map(({ identifier, top, left }, i) => {
        const { variable } = property.markers.find(({ identifier: _identifier }) => (
          _identifier === identifier
        ));
        const {
          name,
          labelUnit,
          label,
          max,
        } = sensorsMeta[variable];
        const value = values.length ? values[0][variable].toFixed(1) : 0;

        return (
          <Marker
            key={i}
            componentIdentifier={identifier}
            positionTop={top}
            positionLeft={left}
            onMouseOver={() => {
              if (!viewerState.pointerDown) {
                setActiveMarker(identifier);
              }
            }}
            onMouseOut={() => setActiveMarker(null)}
            style={{ background: context.primary.base }}
          >
            <div
              style={{
                position: 'absolute',
                display: activeMarker === identifier ? 'block' : 'none',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                whiteSpace: 'nowrap',
              }}
            >
              <Panel elevated padding='reduced' reduced>
                <Title margin="minimal">{name}</Title>
                <Metric
                  label={label}
                  value={value}
                  max={max}
                  unit={labelUnit}
                  oversized
                >
                  <BinaryDonut
                    style={{ width: '2.5rem', height: '2.5rem' }}
                    value={parseFloat(value)}
                    max={max}
                  />
                </Metric>
              </Panel>
            </div>
          </Marker>
        );
      })
  );

  return (
    <>
      <div style={{ position: 'relative' }}>
        {showFloorModel && <Icon
          icon="highlight-off"
          onClick={toggleModel}
          style={{ position: 'absolute', right: 0, zIndex: 1 }}
        />}
        {showFloorModel
          ? <ModelViewer
          modelPath={floorModel}
          viewerState={floorViewerState}
          viewerConfig={({ ...viewerConfig, opacity: 0.5 })}
        />
          : <ModelViewer
          modelPath={houseModel}
          viewerState={viewerState}
          onViewerStateUpdate={onViewerStateUpdate}
          viewerConfig={({ ...viewerConfig, opacity: 0.6 })}
        />
        }
        {viewerState.markers.length > 0 && !showFloorModel && generateMarkers()}
        {selectedComponent !== null && (
          <ComponentInfo
            theme={theme}
            componentId={viewerState.selectedComponentIdentifier}
            component={selectedComponent}
            onClose={deselect}
            toggleModel={toggleModel}
          />
        )}
      </div>
    </>
  );
};

const ModelView = ({ houseModel, floorModel, property }) => (
  <Container>
    <Panel>
      <ModelComponent
        houseModel={houseModel}
        floorModel={floorModel}
        property={property}
      />
    </Panel>
  </Container>
);

export default ModelView;
