import styled from '@emotion/styled';
import {
  Button,
  Panel,
  TextBlock,
  useTheme,
} from '@teron/fabric';
import React from 'react';
import {
  Route,
} from 'react-router-dom';

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-left: 2rem;
`;

const StyledTextBlock = styled(TextBlock)`
  span {
    color: ${({ theme }) => theme.contexts.warning.text.base}; 
  }
`;

const RecommendedActions = ({ property }) => {
  const { theme } = useTheme();

  return (
  <Panel style={{ background: `${theme.context.warning.base}` }}>
    <ContentWrapper>
      <Route path="/properties/:id/heating/">
        <StyledTextBlock margin="none" theme={theme}>
          {property.recommendedActions.heating}
        </StyledTextBlock>
      </Route>
      <Route path="/properties/:id/power/">
        <StyledTextBlock margin="none" theme={theme}>
          {property.recommendedActions.power}
        </StyledTextBlock>
      </Route>
      <ButtonWrapper>
        <Button slim style={{ background: `${theme.contexts.warning.warning.base}`, color: `${theme.contexts.warning.text.contrast}` }}>
          Läs mer
        </Button>
      </ButtonWrapper>
    </ContentWrapper>
  </Panel>
  );
};

export default RecommendedActions;
