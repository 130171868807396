import React from 'react';
import { Group } from '@vx/group';
import { scaleLinear, scaleBand } from '@vx/scale';
import { Bar } from '@vx/shape';
import { useTheme } from '@teron/fabric';
import { AxisBottom, AxisLeft } from '@vx/axis';

const BarLine = ({
  data,
  width,
  height,
  column,
  showAxes = false,
  color = null,
  yScaleHeight,
}) => {
  const { theme: { context } } = useTheme();

  const marginLeft = showAxes ? 60 : 0;
  const marginBottom = showAxes ? 50 : 0;
  const marginTop = showAxes ? 10 : 0;

  const xMax = width - marginLeft;
  const yMax = height - marginBottom - marginTop;

  const getX = (d) => d.date;
  const getY = (d) => d[column];

  const xScale = scaleBand({
    range: [0, xMax],
    domain: data.map(getX),
    padding: 0.4,
  });

  const heightYScale = yScaleHeight ? 16 : 10;
  const yScale = scaleLinear({
    range: [yMax, 0],
    round: true,
    // domain: [0, Math.max(...data.map(getY))],
    domain: [0, heightYScale],
  });

  return (
    <svg width={width} height={height}>
      <Group top={marginTop} left={marginLeft}>
        {showAxes && <AxisLeft
          numTicks={10}
          scale={yScale}
          stroke={context.dividerFaint.base}
          tickStroke={context.dividerFaint.base}
          tickLabelProps={() => ({
            fill: context.textMuted.base,
            fontSize: 10,
            fontFamily: 'sans-serif',
            textAnchor: 'end',
            dx: '-0.25em',
            dy: '0.25em',
          })}
        />}
        {showAxes && <AxisBottom
          numTicks={5}
          scale={xScale}
          top={yMax}
          stroke={context.dividerFaint.base}
          tickStroke={context.dividerFaint.base}
          tickLabelProps={() => ({
            fill: context.textMuted.base,
            fontSize: 10,
            fontFamily: 'sans-serif',
            textAnchor: 'middle',
          })}
        />}
        {data.map((d) => {
          const label = getX(d);
          const barWidth = xScale.bandwidth();
          const barHeight = yMax - yScale(getY(d));
          const barX = xScale(label);
          const barY = yMax - barHeight;
          return <Bar
            key={`bar-${label}`}
            x={barX}
            y={barY}
            width={barWidth}
            height={barHeight}
            fill={color !== null ? color : context.dividerFaint.base}
          />;
        })}
      </Group>
    </svg>
  );
};

export default BarLine;
