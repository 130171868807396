import { subMinutes } from 'date-fns';

const generateSeries = (start, n, labels) => (
  Array(n).fill().map((_, i) => ({
    date: subMinutes(start, i * 15),
    ...labels.reduce((acc, { id, baseValue }) => ({
      ...acc,
      [id]: baseValue + Math.random(),
    }), {}),
  }))
);

const mergeSeries = (...entries) => entries[0].reduce((acc, entry, i) => [
  ...acc,
  Object.keys(entry).reduce((acc2, key) => ({
    ...acc2,
    [key]: key === 'date'
      ? entry[key]
      : (entries.reduce((sum, x) => sum + x[i][key], 0)) / entries.length,
  }), {}),
], []);

export {
  generateSeries,
  mergeSeries,
};
