import styled from '@emotion/styled';
import {
  Button,
  Container,
  ListItem,
  Page,
  PageContent,
  PageHeader,
  Panel,
  PulseMarker,
  Sidebar,
  TextBlock,
  Tooltip,
  useTheme,
} from '@teron/fabric';
import { easeCubic } from 'd3-ease';
import React, { useEffect, useState } from 'react';
import ReactMapGL, {
  FlyToInterpolator,
  Marker as MapMarker,
  WebMercatorViewport,
} from 'react-map-gl';
import { Link } from 'react-router-dom';
import Filter from '../components/Filter';
import { properties } from '../data';
import { usePropertiesContext } from '../providers/PropertiesProvider';

const StyledSeparator = styled.div`
  height: 1px;
  margin: 1.2rem 0;
  background: ${({ theme }) => theme.contexts.primary.textMuted.base};
`;

const FacilitiesView = () => {
  const updateViewport = (lng, lat, zoomIn) => ({
    width: 200,
    height: 200,
    latitude: lat,
    longitude: lng,
    zoom: zoomIn,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: easeCubic,
  });

  const { theme } = useTheme();
  const { selectedProperties } = usePropertiesContext();
  const context = usePropertiesContext();
  const [viewport, setViewport] = useState('');

  const handleMap = (propertiesToShow) => {
    const bbox = propertiesToShow.reduce(
      ([[minLng, minLat], [maxLng, maxLat]], { coordinates: { lat, lng } }) => [
        [Math.min(minLng, lng), Math.min(minLat, lat)],
        [Math.max(maxLng, lng), Math.max(maxLat, lat)],
      ],
      [
        [properties[0].coordinates.lng, properties[0].coordinates.lat],
        [properties[0].coordinates.lng, properties[0].coordinates.lat],
      ],
    );
    const wmviewport = new WebMercatorViewport({
      width: 300,
      height: 300,
    });
    const { longitude, latitude, zoom } = wmviewport.fitBounds(bbox, {
      padding: 20,
    });
    setViewport(updateViewport(longitude, latitude, zoom));
  };

  useEffect(() => {
    if (selectedProperties.length < 1) {
      handleMap(properties);
      return;
    }
    if (selectedProperties.length === 1) {
      setViewport(
        updateViewport(
          selectedProperties[0].coordinates.lng,
          selectedProperties[0].coordinates.lat,
          16,
        ),
      );
      return;
    }
    if (selectedProperties.length > 1) {
      handleMap(selectedProperties);
    }
  }, [selectedProperties]);

  return (
    <Page compact wide>
      <Sidebar width='400px' style={{ overflow: 'auto' }}>
        <>
          <PageHeader pretitle='Översikt' title='Fastigheter' />
          <Filter />
          <StyledSeparator theme={theme} />
          {selectedProperties
            .map((property) => (
              <Panel
                key={property.id}
                padding='reduced'
                style={{ marginBottom: '10px', cursor: 'pointer' }}
                onClick={() => {
                  context.setAreaShown(property.name);
                }}
              >
                <ListItem
                  after={
                    <Link to={`/properties/${property.id}/`}>
                      <Button appearance="primary" size="medium" style={{ padding: '5px 12px' }}>
                        Detaljer
                      </Button>
                    </Link>
                  }
                  subTitle={<>
                    <TextBlock margin="none">{property.address}</TextBlock>
                    <TextBlock margin="none" appearance='textMuted'>
                      {Object.keys(property.buildings).length} {Object.keys(property.buildings).length < 2 ? 'byggnad' : 'byggnader'}
                      , {Object.values(property.buildings)[0].floors.length} {Object.values(property.buildings)[0].floors.length < 2 ? 'våning' : 'våningar'}
                    </TextBlock>
                  </>}
                >
                  {property.name}
                </ListItem>
              </Panel>
            ))}
        </>
      </Sidebar>
      <PageContent expand padding='none'>
        <Container style={{ height: '100%', padding: 'none' }}>
          <ReactMapGL
            {...viewport}
            onViewportChange={(updatedViewport) => setViewport(updatedViewport)}
            mapStyle={theme.external.mapbox}
            width="100%"
            height="100%"
            mapboxApiAccessToken="pk.eyJ1IjoiZ3VycmFtYW4iLCJhIjoiY2s3M3VkdGRkMGZuNDNlbnZ0d3RxZzJqYSJ9.2ngzwjn-VarR-4SXfZR1oA"
          >
            {properties.map((property) => (
              <MapMarker
                key={property.id}
                latitude={property.coordinates.lat}
                longitude={property.coordinates.lng}
              >
                <Tooltip elevated backdrop="light" content={property.name}>
                  <PulseMarker
                    onClick={() => context.setAreaShown(property.name)}
                    status="primary"
                  />
                </Tooltip>
              </MapMarker>
            ))}
          </ReactMapGL>
        </Container>
      </PageContent>
    </Page>
  );
};

export default FacilitiesView;
