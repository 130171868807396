import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { properties } from '../data';

export const Context = createContext();

export const PropertiesProvider = (props) => {
  const [selectedProperties, setSelectedProperties] = useState(properties);
  const [areaShown, setAreaShown] = useState('Samtliga fastigheter');
  const [buildingsRange, setBuildingsRange] = useState([1, 5]);
  const [floorsRange, setFloorsRange] = useState([1, 8]);
  const [disableRange, setDisableRange] = useState(false);

  useEffect(() => {
    if (areaShown !== 'Samtliga fastigheter') {
      const filteredProperty = properties
        .filter((property) => property.name === areaShown);

      const buildings = Object.keys(filteredProperty[0].buildings).length;
      const floors = Object.values(filteredProperty[0].buildings)[0].floors.length;

      setSelectedProperties(filteredProperty);
      setBuildingsRange([buildings, buildings]);
      setFloorsRange([floors, floors]);
      setDisableRange(true);
      return;
    }
    setSelectedProperties(properties);
    setBuildingsRange([1, 5]);
    setFloorsRange([1, 8]);
    setDisableRange(false);
  }, [areaShown]);

  useEffect(() => {
    const filteredProperties = properties
      .filter((property) => (buildingsRange[0] !== 1 || buildingsRange[1] !== 5
        ? Object.keys(property.buildings).length >= buildingsRange[0]
        && Object.keys(property.buildings).length <= buildingsRange[1]
        : property))
      .filter((property) => (floorsRange[0] !== 1 || floorsRange[1] !== 8
        ? Object.values(property.buildings)[0].floors.length >= floorsRange[0]
        && Object.values(property.buildings)[0].floors.length <= floorsRange[1]
        : property));
    setSelectedProperties(filteredProperties);
  }, [buildingsRange, floorsRange]);

  const propertiesContext = {
    selectedProperties,
    areaShown,
    setAreaShown,
    buildingsRange,
    setBuildingsRange,
    floorsRange,
    setFloorsRange,
    disableRange,
  };

  return (
    <Context.Provider value={propertiesContext}>
      {props.children}
    </Context.Provider>
  );
};

const usePropertiesContext = () => useContext(Context);

export default PropertiesProvider;
export { usePropertiesContext };
