import React from 'react';
import { css } from '@emotion/core';

const style = ({ size, clickable }) => css`
  width: ${size};
  height: ${size};
  cursor: ${clickable ? 'pointer' : 'inherit'};
  display: inline-block;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Teron = ({ size = '1.5rem', onClick, ...props }) => (
  <div
    className="fabric--icon"
    css={style({ size, clickable: typeof onClick !== 'undefined' })}
    onClick={onClick}
    {...props}
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      version="1.1"
      id="svg2163">
      <defs
        id="defs2167" />
      <path
        d="M 0.81621885,0.81621885 H 23.183781 V 23.183781 H 0.81621885 Z"
        fill="none"
        style={{ strokeWidth: 0.999998 }} />
      <path
        d="m 19.34051,15.364557 c 1.483996,0 1.424637,-0.633172 1.602716,-0.633172 0.178079,0 0.158292,0.138506 0.118719,0.395732 0,0.03957 -0.01979,0.11872 -0.03957,0.277013 -0.514453,3.28458 -3.245006,5.421535 -6.470226,5.421535 h -2.710767 c -2.2358884,0 -3.4230859,-1.523571 -3.4230859,-3.423086 0,-0.296799 0.039572,-0.573812 0.098934,-0.850825 0.1780794,-0.712319 0.811251,-1.167411 1.6027159,-1.167411 z"
        style={{ fill: 'currentColor', strokeWidth: 0.999998 }} />
      <path
        d="m 19.439443,7.8654273 c 0.929971,0 1.681862,-0.7518916 1.681862,-1.6818628 v 0 -2.6118341 c 0,-0.257226 0.01979,-0.415519 -0.158292,-0.3957324 -0.17808,0.019787 -0.118721,0.6331719 -1.622503,0.6331719 h -6.509799 c -0.771679,0 -1.424637,0.5144522 -1.622503,1.2663437 0,0 -0.751892,2.9482066 -0.791464,3.0669264 -0.05936,0.257226 -0.01979,0.3957323 0.138506,0.3957323 0.158292,0 0.11872,-0.6331717 1.622503,-0.6331717 z"
        style={{ fill: 'currentColor', strokeWidth: 0.999998 }} />
      <path
        d="m 19.439443,13.662908 c 0.929971,0 1.681862,-0.751893 1.681862,-1.681864 v 0 -2.6316202 c 0,-0.2572261 0.01979,-0.4155191 -0.158292,-0.3957325 -0.17808,0.019787 -0.118721,0.633172 -1.622503,0.633172 h -7.855289 c -0.771679,0 -1.424637,0.5144517 -1.6225033,1.2663437 0,0 -0.7518911,2.948207 -0.7914649,3.066927 -0.059358,0.257226 -0.019786,0.395732 0.1385064,0.395732 0.1582934,0 0.1187196,-0.633172 1.6225028,-0.633172 z"
        style={{ fill: '#ffb300', strokeWidth: 0.999998 }} />
      <path
        d="m 6.6770719,17.481725 c 0,-0.296799 0.039573,-0.613385 0.098933,-0.910184 L 9.2295461,5.9263384 C 9.4867722,5.0359404 8.9723201,4.1257558 8.0819224,3.8685297 7.943416,3.8289565 7.7851226,3.8091699 7.6268301,3.8091699 H 4.6786231 C 3.1946265,3.8091699 3.2341997,3.175998 3.0561201,3.175998 2.8780406,3.1562114 2.8780406,3.334291 2.8780406,3.591517 v 11.139868 c 0,2.394182 2.0380219,5.362174 5.0455885,5.97556 0.257226,0.05936 0.3957325,0.09893 0.4353053,-0.07914 C 8.3787204,20.548657 8.0225617,20.350788 7.7059759,20.152922 7.0134445,19.69783 6.6374986,18.589779 6.6770719,17.481728 Z"
        style={{ fill: 'currentColor', strokeWidth: 0.999998 }} />
    </svg>
  </div>
);

export default Teron;
