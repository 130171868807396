import React from 'react';
import styled from '@emotion/styled';
import {
  BinaryDonut,
  Grid,
  Cell,
  Panel,
  Metric,
  Title,
} from '@teron/fabric';

import { sensors as sensorsMeta } from '../data';

const StyledPanel = styled(Panel)`
 // TODO: Why does this not work?
  cursor: pointer;
`;

const SensorTabs = ({
  sensors,
  activeSensor,
  setActiveSensor,
  setActiveBuilding,
  values,
}) => (
  <Grid columns={sensors.length} align="stretch">
    {sensors.map((id) => {
      const {
        name,
        labelUnit,
        label,
        max,
      } = sensorsMeta[id];
      const value = values.length ? values[values.length - 1][id].toFixed(1) : 0;

      const multiSelectSeries = (sensorId) => {
        setActiveBuilding(null);
        if (activeSensor.length === 1 && activeSensor.includes(sensorId)) {
          return;
        }
        if (activeSensor.includes('basepower')
        || activeSensor.includes('returntempscore')) {
          setActiveSensor([sensorId]);
          return;
        }
        if (activeSensor.includes(sensorId)) {
          const sensorArray = [...activeSensor];
          const index = sensorArray.indexOf(sensorId);
          sensorArray.splice(index, 1);
          setActiveSensor(sensorArray);
          return;
        }
        setActiveSensor([...activeSensor, sensorId]);
      };

      return <Cell width={1} key={id}>
        <StyledPanel
          style={{ cursor: 'pointer' }}
          active={activeSensor.includes(id)}
          onClick={() => multiSelectSeries(id)}
        >
          <Title margin="minimal">{name}</Title>
          <Metric
            label={label}
            value={value}
            max={max}
            unit={labelUnit}
            oversized
          >
            <BinaryDonut
              style={{ width: '2.5rem', height: '2.5rem' }}
              value={parseFloat(value)}
              max={max}
            />
          </Metric>
        </StyledPanel>
      </Cell>;
    })}
  </Grid>
);

export default SensorTabs;
