import React from 'react';
import { css } from '@emotion/core';
import {
  Icon,
  useTheme,
} from '@teron/fabric';

const Weather = ({ temperature, humidity, ppm }) => {
  const { theme } = useTheme();
  const { context } = theme;

  return <div css={css`color: ${context.textMuted.base};`}>
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0.2rem;
      `}
    >
      <Icon icon="wb-sunny" />
      <div
        css={css`
          font-weight: ${theme.typography.normal.medium};
          margin-left: 0.5rem;
        `}
      >{temperature} °C</div>
    </div>
    <div css={css`font-size: 0.9rem;`}>{ppm} ppm, {humidity} %</div>
  </div>;
};

export default Weather;
